<template>
  <div
    :style="{
     
      minHeight: '600px',
      borderRadius: '5%',
    }"
    class="white pa-3"
  >
    <div :style="{ flex: '45%', margin: '0%' }">
      <v-card flat>
        <v-card-title>Top Clientes </v-card-title>
        <v-card-text>
          <div :style="{ marginBlock: '5px' }">
            <div
              :style="{
                display: 'flex',
                flexWrap: 'wrap',
                flex: 1,
                textAlign: 'left',
              }"
            >
              <div :style="{ flex: 1, fontWeight: 'bolder' }">
                Filtrar por fecha
              </div>
            </div>
            <div>
              <div
                :style="{
                  display: 'flex',
                  flexWrap: 'wrap',
                  flexDirection: 'column',
                  alignItems: 'left',
                }"
              >
                <div class="dx-field">
                  <div class="dx-field-label">Desde</div>
                  <div class="dx-field-value">
                    <date-picker
                      v-model="start"
                      @dateSelected="
                        (startP) => {
                          start = startP;
                        }
                      "
                      :value="start"
                      type="date"
                      :styling-mode="'underlined'"
                      :style="{ width: '150px' }"
                    ></date-picker>
                  </div>
                </div>

                <div>
                  <div class="dx-field" style="margin-bottom: 20px">
                    <div
                      class="dx-field-label"
                      style="display: inline-block; vertical-align: middle"
                    >
                      Hasta
                    </div>
                    <div class="dx-field-value" style="display: inline-block">
                      <date-picker
                        v-model="end"
                        @dateSelected="
                          (endP) => {
                            end = endP;
                          }
                        "
                        :value="end"
                        type="date"
                        :styling-mode="'underlined'"
                        :style="{ width: '150px' }"
                      ></date-picker>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <DxButton
                :width="100"
                text="aplicar"
                type="default"
                styling-mode="contained"
                @click="getTopCustomersRange()"
              />
            </div>
            <div
              :style="{
                display: 'flex',
                flexWrap: 'wrap',
                flex: 1,
                textAlign: 'center',
              }"
            >
              <!--    <div :style="{ flex: 1 }">Viendo ùltimo : {{ activeC }}</div> -->
            </div>
          </div>
          <div>
            <GeneralFilter
              :search="{}"
              :filterEndpoint="filterPromise"
              @filtered="filterHandler"
              @emptyFilter="clearFilter"
              @exporting="onExporting"
              style="width: 100%;"
            >
              <DxDataGrid
                :data-source="!isFiltered ? topCustomers : topCustomersFiltered"
                key-expr="ID_Customer"
                :scrolling="{ useNative: true }"    
                      :column-auto-width="true"
                :show-row-lines="showRowLines"
                :show-borders="showBorders"
                :row-alternation-enabled="rowAlternationEnabled"
                style="overflow-x: auto;"
              
              >
                <DxExport :enabled="true" />
              
                <DxPaging :page-size="5" />
                <DxPager
                  :visible="true"
                  :show-page-size-selector="false"
                  :show-info="true"
                  :show-navigation-buttons="true"
                />
                <DxColumn
                  :minwidth=" columnWidth"
                  data-field="ID_Customer"
                  caption="ID"
                ></DxColumn>

                <DxColumn
                :minwidth=" columnWidth"
                  cell-template="name-template"
                  caption="Nombre de Cliente"
                ></DxColumn>
                <!-- <DxColumn data-field="FirstName" caption="Nombre de Cliente"></DxColumn>
                <DxColumn data-field="LastName" caption="Apellido de Cliente"></DxColumn> -->
                <DxColumn
                :minwidth=" columnWidth"
                  cell-template="email-template"
                  caption="Correo de Cliente"
                ></DxColumn>

                <DxColumn
                :minwidth=" columnWidth"
                  cell-template="type-template"
                  :showEditorAlways="false"
                  caption="Es negocio"
                ></DxColumn>
                <DxColumn
                :minwidth=" columnWidth"
                  data-field="amountFormatted"
                  caption="Monto"
                ></DxColumn>

                <template #type-template="{ data }">
                  <p v-if="data.data.Is_a_business === 1">Negocio</p>
                  <p v-else>Cliente</p>
                </template>
                <template #name-template="{ data }">
                  <p v-if="data.data.Is_a_business === 1">
                    {{ data.data.BusinessName }}
                  </p>
                  <p v-else>{{ data.data.FirstName }} {{ data.data.LastName }}</p>
                </template>
                <template #email-template="{ data }">
                  <p v-if="data.data.Is_a_business">
                    {{ data.data.BusinessEmail }}
                  </p>
                  <p v-else>
                    {{ data.data.Email }}
                  </p>
                </template>
              </DxDataGrid>
            </GeneralFilter>
          </div>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
import { printToExcel } from "@/helpers/printToexcel";
//import Block from "@/components/Block"
// eslint-disable-next-line no-unused-vars
/* import ChartBar from "../DashboardStats/ChartBar.vue";*/
/* import ChartLine from "../DashboardStats/ChartLine.vue"; */
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

import {
  DxDataGrid,
  DxColumn,
  
  DxPaging,
  DxPager,
  DxExport,
} from "devextreme-vue/data-grid";
import GeneralFilter from "../GeneralFilter.vue";
import DxButton from "devextreme-vue/button";
import DatePicker from "../../components/DatePicker";
import { multipleCriteriaFilter } from "../../helpers/object";

/* import { format } from "path"; */
ChartJS.register(ArcElement, Tooltip, Legend);
export default {
  name: "TopCustomerChart",
  components: {
    /*  ChartBar, */
    /*  ChartLine, */
    DxDataGrid,
    DxColumn,
    DatePicker,

    DxPaging,
    DxButton,
    DxPager,
    GeneralFilter,
    DxExport,
  },
  data() {
    return {
      start: new Date(),
      end: new Date(),
      panel: 0,
      showCustomer: false,
      topCustomers: [],
      topProducts: [],
      activeP: "dia",
      activeC: "dia",
      activePF: "Dias",
      countCustomers: [],
      showRowLines: true,
      showBorders: true,
      rowAlternationEnabled: true,
      search: {},
      showPageSizeSelector: true,
      showInfo: true,
      showNavButtons: true,
      topCustomersFiltered: [],
      isFiltered: false,
      dataP: {
        labels: ["Actual", "Anterior", "Dos anteriores"],
        datasets: [
          {
            backgroundColor: ["#41B883", "#E9351", "#00D6FF"],
            data: [10, 10, 10],
          },
        ],
      },
      options: {
        responsive: false,
        width: "200px",
        heigth: "200px",
        maintainAspectRatio: false,
      },
      Currency: null,
    };
  },

  mounted() {
    this.Currency = JSON.parse(localStorage.getItem("branch")).Currency;
    this.getTopCustomers();
    this.getTopProducts();
    /*     this.getCustomersDay(); */
  },

  methods: {
    onExporting(e) {
      printToExcel(e, "Top Clientes");
    },
    setCurrency() {
      this.topCustomers.map((customer) => {
        customer.amountFormatted = `${this.Currency}${customer.amount}`;
      });
    },
    filterHandler(response) {
      this.isFiltered = true;
      this.topCustomersFiltered = response;
    },
    clearFilter() {
      this.isFiltered = false;
    },
    filterPromise(filterObj) {
      const filterArray = [
        "ID_Customer",
        "BusinessName",
        "FirstName",
        "LastName",
        "BusinessEmail",
        "Email",
      ];
      return new Promise((resolve, reject) => {
        try {
          resolve(
            multipleCriteriaFilter(filterArray, filterObj, this.topCustomers)
          );
        } catch (error) {
          reject(error);
        }
      });
    },
    getTopCustomers() {
      this.$API.dashboard.getTop().then((response) => {
        this.topCustomers = response;
        this.activeC = "dia";
        this.setCurrency();
      });
    },
    getTopCustomersRange() {
      this.$API.dashboard
        .getTopCustomersRange(
          new Date(this.formatDate(this.start)),
          new Date(this.formatDate(this.end))
        )
        .then((response) => {
          this.topCustomers = response;
          this.activeC = "dia";
          this.setCurrency();
        });
    },
    formatDate(date) {
      const parts = date.split("/");
      const aux = parts[0];
      parts[0] = parts[1];
      parts[1] = aux;
      return parts.join("/");
    },
    getTopCustomersw() {
      this.$API.dashboard.getTopCustomersw().then((response) => {
        this.topCustomers = response;
        this.activeC = "semana";
        this.setCurrency();
      });
    },
    getTopCustomersm() {
      this.$API.dashboard.getTopCustomersm().then((response) => {
        this.topCustomers = response;
        this.activeC = "mes";
        this.setCurrency();
      });
    },
    getTopCustomersy() {
      this.$API.dashboard.getTopCustomersy().then((response) => {
        this.topCustomers = response;
        this.activeC = "año(actual)";
        this.setCurrency();
      });
    },
    //pastel graphic
    getCustomersYear() {
      this.$API.dashboard.CustomersYear().then((response) => {
        this.countCustomers = response;
        this.activePF = "años";
        this.setCurrency();
        this.dataP = {
          labels: ["Actual", "Anterior", "Dos anteriores"],
          datasets: [
            {
              backgroundColor: ["#41B883", "#E9351", "#00D6FF"],
              data: this.countCustomers,
            },
          ],
        };
      });
    },
    getCustomersMonth() {
      this.$API.dashboard.CustomersMonth().then((response) => {
        this.countCustomers = response;
        this.activePF = "meses";
        this.setCurrency();
        this.dataP = {
          labels: ["Actual", "Anterior", "Dos anteriores"],
          datasets: [
            {
              backgroundColor: ["#41B883", "#E9351", "#00D6FF"],
              data: this.countCustomers,
            },
          ],
        };
      });
    },
    getCustomersDay() {
      this.$API.dashboard.CustomersDay().then((response) => {
        this.countCustomers = response;
        this.setCurrency();
        this.dataP = {
          labels: ["Actual", "Anterior", "Dos anteriores"],
          datasets: [
            {
              backgroundColor: ["#41B883", "#E9351", "#00D6FF"],
              data: this.countCustomers,
            },
          ],
        };
        this.activePF = "dias";
      });
    },
    //end pastel graphic
    getTopProducts() {
      this.$API.dashboard.getProducts().then((response) => {
        this.topProducts = response;
        this.setCurrency();
        this.activeP = "dia";
      });
    },
    getTopProductsw() {
      this.$API.dashboard.getProductsw().then((response) => {
        this.topProducts = response;
        this.setCurrency();
        this.activeP = "Semana";
      });
    },
    getTopProductsm() {
      this.$API.dashboard.getProductsm().then((response) => {
        this.topProducts = response;
        this.setCurrency();
        this.activeP = "mes";
      });
    },
    getTopProductsy() {
      this.$API.dashboard.getProductsy().then((response) => {
        this.topProducts = response;
        this.setCurrency();
        this.activeP = "año(actual)";
      });
    },
  },
  computed: {
    columnWidth() {
    
    return this.$vuetify.breakpoint.smAndDown ? '150px' : 'auto !important';
  },
  },
};
</script>
<style scoped>
.number-statistics .c-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 40px;
  border-radius: 8px;
}

.dx-theme-generic-typography h3 {
  color: #000000;
}

.table-statistics .purple {
  background-color: purple;
}

.table-statistics .orange {
  background-color: orange;
}

.table-statistics h4 {
  color: #fff;
  padding: 10px 20px;
}

.dx-field {
  display: flex;
  align-items: center;
}
.dx-field-label {
  margin-right: 10px;
}

.dx-data-grid .dx-datagrid-headers, .dx-data-grid .dx-datagrid-rowsview {
  overflow-x: auto;
}
.dx-column{
  min-height: 150px;
}


</style>
