<template>
  <v-col cols="12">
    <div>
      <div style="display: flex; flex-wrap: wrap;">
        <div class="dx-field m-2" style="display: flex; align-items: center;flex-wrap: wrap;">
          <div class="dx-field-label" style="min-width: 270px; margin-right: 5px;">Selecciona La Fecha de Inicio</div>
          <div class="dx-field-value">
            <date-picker @dateSelected="(startP) => {
                start = startP
              }" v-model="start" :value="start"></date-picker>
          </div>
        </div>
        <div class="dx-field m-2" style="display: flex; align-items: center; flex-wrap: wrap;">
          <div class="dx-field-label" style="min-width: 270px; margin-right: 5px;">Selecciona La Fecha Final</div>
          <div class="dx-field-value">
            <date-picker @dateSelected="(endP) => (end = endP)" v-model="end" :value="end"></date-picker>
          </div>
        </div>
      </div>
      <div>
        <DxButton :width="100" text="aplicar" type="default" styling-mode="contained" @click="getTopCustomersByDate()" />
      </div>
    </div>
    <div class="white pa-3" :style="{  borderRadius:'5%' }" v-if="total>=1">
      <DxPieChart
        id="pie"
        :data-source="productsAndServices"
        type="doughnut"
        title="Resumen de ventas de Productos y Servicios"
        palette="Soft Pastel"
        center-template="centerTemplate"
        :inner-radius="0.65"
          resolve-label-overlapping="shift"
          size-group="piesGroup"
      >
        <DxSeries
          argument-field="ItemDescription"
          value-field="val"
        >
          <DxLabel :visible="false" format="fixedPoint">
            <DxConnector :visible="true"  />
          </DxLabel>
        </DxSeries>
        <DxExport :enabled="true" />
        <DxLegend :visible="false"
          :margin="0"
          horizontal-alignment="right"
          vertical-alignment="top"
        />
        <DxTooltip :enabled="true" :customize-tooltip="customizeTooltip">
          <DxFormat type="millions" />
        </DxTooltip>
        <template #centerTemplate>
          <template>
            <svg>
              <circle
  
                cx="100"
                cy="100"
                fill="#eee"
              />
  
              <text
                :style="{ fontSize: 18, fill: '#494949' }"
                text-anchor="middle"
                x="100"
                y="120"
              >
                <tspan x="100">Ventas total</tspan>
                <tspan :style="{ fontWeight: 600 }" x="100" dy="20px">
                $ {{ numberWithCommas( total.toFixed(2) )}}
                </tspan>
              </text>
            </svg>
          </template>
        </template>
      </DxPieChart>
    </div>
    <div v-else style="height: 415px !important" >
      <h2 style="text-align: center; top: 50%; transform: translateY(475%);">Aún no hay ventas para mostrar</h2>
    </div>
  </v-col>
</template>
<script>
import DxPieChart, {
  DxLegend,
  DxSeries,
  DxTooltip,
  DxFormat,
  DxLabel,
  DxConnector,
  DxExport,
} from "devextreme-vue/pie-chart";
import DxButton from "devextreme-vue/button";
import DatePicker from '../../components/DatePicker'
import { numberWithCommas } from "../../helpers/money";

export default {
  components: {
    DxPieChart,
    DxLegend,
    DxSeries,
    DxTooltip,
    DxFormat,
    DxLabel,
    DxConnector,
    DxExport,
    DatePicker,
    DxButton
  },
  data() {
    return {
      productsAndServices: [],
      total: null,
      start: new Date(),
      end: new Date(),
    };
  },
  mounted() {
    this.getTopCustomers();
  },
  methods: {
    numberWithCommas( value ) {
      return numberWithCommas( value );
    },
    getTopCustomers() {
      this.$API.dashboard.getProductsServices().then((response) => {
        this.productsAndServices = response;
        response.forEach((element) => {
          if (element.val) {
            this.total += element.val;
          }
        });
      });
    },
    getTopCustomersByDate() {
      this.total = 0;
      this.$API.dashboard.getProductsServicesByDate({ start: new Date(this.formatDate(this.start)), end: new Date(this.formatDate(this.end)) }).then((response) => {
        console.log(response);
        this.productsAndServices = response;
        response.forEach((element) => {
          if (element.val) {
            this.total += element.val;
          }
        });
        /*  this.activeC = "dia"; */
        console.log(this.total);
      });
    },
    formatDate(date){
      const parts = date.split('/');
      const aux = parts[0];
      parts[0] = parts[1];
      parts[1] = aux;
      return parts.join('/');
    },
    customizeTooltip(param) {
      const { argumentText, percent, value } = param;
      return {
        text: `${argumentText} - ${(percent * 100).toFixed(2)}% - $${value.toFixed(2)}`,
      };
    },
  },
};
</script>
<style>
#pie {
  height: 415px;
}
</style>
<style scoped>
.pies-container {
  margin: auto;
  width: 800px;
}

.pies-container > div {
  width: 400px;
  float: left;
  margin-top: -50px;
}

.long-title h3 {
  font-weight: 200;
  font-size: 28px;
  text-align: center;
  margin-bottom: 20px;
}
</style>
