var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"white pa-3",style:({
   
    minHeight: '600px',
    borderRadius: '5%',
  })},[_c('div',{style:({ flex: '45%', margin: '0%' })},[_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',[_vm._v("Top Clientes ")]),_c('v-card-text',[_c('div',{style:({ marginBlock: '5px' })},[_c('div',{style:({
              display: 'flex',
              flexWrap: 'wrap',
              flex: 1,
              textAlign: 'left',
            })},[_c('div',{style:({ flex: 1, fontWeight: 'bolder' })},[_vm._v(" Filtrar por fecha ")])]),_c('div',[_c('div',{style:({
                display: 'flex',
                flexWrap: 'wrap',
                flexDirection: 'column',
                alignItems: 'left',
              })},[_c('div',{staticClass:"dx-field"},[_c('div',{staticClass:"dx-field-label"},[_vm._v("Desde")]),_c('div',{staticClass:"dx-field-value"},[_c('date-picker',{style:({ width: '150px' }),attrs:{"value":_vm.start,"type":"date","styling-mode":'underlined'},on:{"dateSelected":(startP) => {
                        _vm.start = startP;
                      }},model:{value:(_vm.start),callback:function ($$v) {_vm.start=$$v},expression:"start"}})],1)]),_c('div',[_c('div',{staticClass:"dx-field",staticStyle:{"margin-bottom":"20px"}},[_c('div',{staticClass:"dx-field-label",staticStyle:{"display":"inline-block","vertical-align":"middle"}},[_vm._v(" Hasta ")]),_c('div',{staticClass:"dx-field-value",staticStyle:{"display":"inline-block"}},[_c('date-picker',{style:({ width: '150px' }),attrs:{"value":_vm.end,"type":"date","styling-mode":'underlined'},on:{"dateSelected":(endP) => {
                          _vm.end = endP;
                        }},model:{value:(_vm.end),callback:function ($$v) {_vm.end=$$v},expression:"end"}})],1)])])])]),_c('div',[_c('DxButton',{attrs:{"width":100,"text":"aplicar","type":"default","styling-mode":"contained"},on:{"click":function($event){return _vm.getTopCustomersRange()}}})],1),_c('div',{style:({
              display: 'flex',
              flexWrap: 'wrap',
              flex: 1,
              textAlign: 'center',
            })})]),_c('div',[_c('GeneralFilter',{staticStyle:{"width":"100%"},attrs:{"search":{},"filterEndpoint":_vm.filterPromise},on:{"filtered":_vm.filterHandler,"emptyFilter":_vm.clearFilter,"exporting":_vm.onExporting}},[_c('DxDataGrid',{staticStyle:{"overflow-x":"auto"},attrs:{"data-source":!_vm.isFiltered ? _vm.topCustomers : _vm.topCustomersFiltered,"key-expr":"ID_Customer","scrolling":{ useNative: true },"column-auto-width":true,"show-row-lines":_vm.showRowLines,"show-borders":_vm.showBorders,"row-alternation-enabled":_vm.rowAlternationEnabled},scopedSlots:_vm._u([{key:"type-template",fn:function({ data }){return [(data.data.Is_a_business === 1)?_c('p',[_vm._v("Negocio")]):_c('p',[_vm._v("Cliente")])]}},{key:"name-template",fn:function({ data }){return [(data.data.Is_a_business === 1)?_c('p',[_vm._v(" "+_vm._s(data.data.BusinessName)+" ")]):_c('p',[_vm._v(_vm._s(data.data.FirstName)+" "+_vm._s(data.data.LastName))])]}},{key:"email-template",fn:function({ data }){return [(data.data.Is_a_business)?_c('p',[_vm._v(" "+_vm._s(data.data.BusinessEmail)+" ")]):_c('p',[_vm._v(" "+_vm._s(data.data.Email)+" ")])]}}])},[_c('DxExport',{attrs:{"enabled":true}}),_c('DxPaging',{attrs:{"page-size":5}}),_c('DxPager',{attrs:{"visible":true,"show-page-size-selector":false,"show-info":true,"show-navigation-buttons":true}}),_c('DxColumn',{attrs:{"minwidth":_vm.columnWidth,"data-field":"ID_Customer","caption":"ID"}}),_c('DxColumn',{attrs:{"minwidth":_vm.columnWidth,"cell-template":"name-template","caption":"Nombre de Cliente"}}),_c('DxColumn',{attrs:{"minwidth":_vm.columnWidth,"cell-template":"email-template","caption":"Correo de Cliente"}}),_c('DxColumn',{attrs:{"minwidth":_vm.columnWidth,"cell-template":"type-template","showEditorAlways":false,"caption":"Es negocio"}}),_c('DxColumn',{attrs:{"minwidth":_vm.columnWidth,"data-field":"amountFormatted","caption":"Monto"}})],1)],1)],1)])],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }