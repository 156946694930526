<template>
    <v-expansion-panel v-bind="$attrs" v-model="panel" >
      <v-expansion-panel-header class="mt-2"> Dashboard
        <template v-slot:actions>
          <v-btn @click.native.stop="forcesUpdate()" icon small>
              <v-icon color="primary">mdi-reload</v-icon>
          </v-btn>
          <!-- <v-icon color="primary" @click.native="forcesUpdate()">mdi-chevron-down</v-icon> -->
        </template>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <template>
          <!--     <v-row class="number-statistics mb-2 mt-0" #align-content-md>
            <div class="text-center">Ultimos 7 dias estadisticas</div>
          </v-row> -->
          <v-row class="number-statistics m-4 mt-2">
            <v-col cols="12" sm="12" md="6" lg="3" class="mb-3" v-if="$store.getters['rolesUser/getCustomRole'](54)">
              <div class="box pa-3 shadow p-3">
                <div class="d-flex align-center justify-space-between">
                  <div class="warning c-icon">
                    <v-icon class="white--text">mdi-account-multiple</v-icon>
                  </div>
                  <div class="text-right">
                    <h5>Total Clientes</h5>
                    {{ lineData.customersTotal }}
                  </div>
                </div>
              </div>
            </v-col>

            <v-col cols="12" sm="12" md="6" lg="3" class="mb-3" v-if="$store.getters['rolesUser/getCustomRole'](55)">
              <div class="box pa-3 shadow p-3">
                <div class="d-flex justify-space-between">
                  <div class="c-icon success">
                    <v-icon class="white--text">mdi-cash-multiple</v-icon>
                  </div>
                  <div class="text-right">
                    <h5>7 dias Facturación</h5>
                    ${{ lineData.salesAmount }}
                  </div>
                </div>
              </div>
            </v-col>

            <v-col cols="12" sm="12" md="6" lg="3" class="mb-3" v-if="$store.getters['rolesUser/getCustomRole'](56)">
              <div class="box pa-3 shadow p-3">
                <div class="d-flex justify-space-between">
                  <div class="warning c-icon">
                    <v-icon class="white--text">mdi-account-multiple</v-icon>
                  </div>
                  <div class="text-right">
                    <h5>Nuevos clientes 7 dias</h5>
                    {{ lineData.customers }}
                  </div>
                </div>
              </div>
            </v-col>

            <v-col cols="12" sm="12" md="6" lg="3" class="mb-3" v-if="$store.getters['rolesUser/getCustomRole'](57)">
              <div class="box pa-3 shadow p-3">
                <div class="d-flex justify-space-between">
                  <div class="c-icon success">
                    <v-icon class="white--text">mdi-cash-multiple</v-icon>
                  </div>
                  <div class="text-right">
                    <h5>Mes Facturación</h5>
                    $ {{ lineData.salesTotal }}
                  </div>
                </div>
              </div>
            </v-col>
          </v-row>
          <v-row>

            <v-col v-if="businessType != 'accounting_and_bill' && ($store.getters['rolesUser/getCustomRole'](58))" cols="12" md="6" lg="6" class="mb-3">
              <div class="box pa-3 shadow p-3">
                <div class="d-flex justify-space-between">
                  <div class="warning c-icon">
                    <v-icon class="white--text">mdi-account-multiple</v-icon>
                  </div>
                  <div class="text-right">
                    <h5>Total de pacientes</h5>
                    {{ lineData.totalPatients }}
                  </div>
                </div>
              </div>
            </v-col>
            <v-col v-if="businessType != 'accounting_and_bill' && ($store.getters['rolesUser/getCustomRole'](59))" cols="12" :md="businessType != 'accounting_and_bill' ? 6 : 12" lg="6" class="mb-3">
              <div class="box pa-3 shadow p-3">
                <div class="d-flex justify-space-between">
                  <div class="warning c-icon">
                    <v-icon class="white--text">mdi-account-multiple</v-icon>
                  </div>
                  <div class="text-right">
                    <h5>Pacientes en los últimos 7 días</h5>
                    {{ lineData.patients7Days }}
                  </div>
                </div>
              </div>
            </v-col>
          </v-row>
          <v-row style="margin-left: 0.5rem; margin-right: 0.5rem">
            <template v-if="businessType == 'bloodtestlaboratory'">
              <v-col cols="12"  sm="12"  md="6" lg="6" xl="6" class="mb-3"  v-if="$store.getters['rolesUser/getCustomRole'](63)">
                <div class="box box-m">
                  <ChartLine :key="chartLine" />
                </div>
              </v-col>

              <v-col cols="12"  sm="12" md="6" lg="6" xl="6" class="mb-3" v-if="$store.getters['rolesUser/getCustomRole'](64)">
                <div class="box box-sm">
                  <ChartLineWeek :key="chartLineWeek" />
                </div>
              </v-col>
              <v-col cols="12"  sm="12" md="6" lg="6" xl="6" class="mb-3" v-if="$store.getters['rolesUser/getCustomRole'](66)">
                <div class="box">
                  <TopProductChart :key="topProductChart"></TopProductChart>
                </div>
              </v-col>
            </template>
            <template v-else>
              <!--v-col cols="12"  sm="12" md="12" lg="12" xl="12" class="mb-3" v-if="businessType != 'accounting_and_bill' && ($store.getters['rolesUser/getCustomRole'](60))">
                <div class="box" style="height: 100%">
                  <PatientChart  :key="patientChart" />
                </div>
              </v-col-->
              <v-col cols="12"  sm="12" md="6" lg="6" xl="6" class="mb-3" v-if="$store.getters['rolesUser/getCustomRole'](61)">
                <div class="box" style="height: 100%">
                  <Doghnut />
                </div>
              </v-col>
              <v-col cols="12"  sm="12" md="6" lg="6" xl="6" class="mb-3" v-if="$store.getters['rolesUser/getCustomRole'](62)">
                <div class="box" style="height: 100%">
                  <ChartProductsWarehouses />
                </div>
              </v-col>
              <v-col cols="12"  sm="12" md="6" lg="6" xl="6" class="mb-3" v-if="$store.getters['rolesUser/getCustomRole'](63)">
                <div class="box box-m" style="height: 100%">
                  <ChartLine :key="chartLine" />
                </div>
              </v-col>

              <v-col cols="12"  sm="12" md="6" lg="6" xl="6" class="mb-3" v-if="$store.getters['rolesUser/getCustomRole'](64)">
                <div class="box box-sm" style="height: 100%"> 
                  <ChartLineWeek :key="chartLineWeek" />
                </div>
              </v-col>
              <v-col cols="12"  sm="12" md="6" lg="6" xl="6" class="mb-3" v-if="$store.getters['rolesUser/getCustomRole'](65)">
                <div class="box" style="height: 97%">
                  <TopProductChart :key="topProductChart"></TopProductChart>
                </div>
              </v-col>
            </template>
            <v-col cols="12"  sm="12" md="6" lg="6" xl="6" class="mb-3" v-if="$store.getters['rolesUser/getCustomRole'](66)">
              <div class="box" style="height: 97%" >
                <TopCustomerChart :key="topCustomerChart" />
              </div>
            </v-col>
          </v-row>
        </template>
      </v-expansion-panel-content>
    </v-expansion-panel>
</template>
<script>
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

import TopCustomerChart from "../DashboardStats/TopCustomerChart.vue";
import TopProductChart from "../DashboardStats/TopProductChart.vue";
import Doghnut from "../DashboardStats/Doghnut.vue";
import ChartLine from "../DashboardStats/ChartLine.vue";
//import PatientChart from "../DashboardStats/PatientChart.vue";
import ChartProductsWarehouses from "../DashboardStats/ChartProductsWarehouses.vue";
import /* DxDataGrid,
  DxColumn,
  DxScrolling,
  DxPaging, */
"devextreme-vue/data-grid";
import ChartLineWeek from "../DashboardStats/ChartLineWeek.vue";
import { mapState } from "vuex";
ChartJS.register(ArcElement, Tooltip, Legend);
export default {
  name: "Dashboard",
  components: {
    Doghnut,
    TopCustomerChart,
    ChartLine,
    TopProductChart,
    ChartLineWeek,
    //PatientChart,
    ChartProductsWarehouses
  },
  data() {
    return {
      panel: 1,
      showCustomer: false,
      businessType: "",
      topCustomers: [],
      topProducts: [],
      activeP: "dia",
      activeC: "dia",
      activePF: "Dias",
      patientChart: 0,
      chartLine: 0,
      topProductChart: 0,
      topCustomerChart: 0,

      lineData: {
        customersTotal: 0,
      },
      chartLineWeek: 0,
      countCustomers: [],
      showRowLines: true,
      showBorders: true,
      rowAlternationEnabled: true,
      businesstype: JSON.parse(localStorage.getItem("user")).businesstype,
      search: {},
      dataP: {
        labels: ["Actual", "Anterior", "Dos anteriores"],
        datasets: [
          {
            backgroundColor: ["#41B883", "#E9351", "#00D6FF"],
            data: [10, 10, 10],
          },
        ],
      },
      options: {
        responsive: false,
        width: "200px",
        heigth: "200px",
        maintainAspectRatio: false,
      },
    };
  },
  computed: {
    /*   isPanelOpened: function(){
          if (this.panel == 0){
          return  this.forcesUpdate()
          } else {
            return this.forcesUpdate()
          }
        } */
      
    ...mapState(
      "rolesUser",
      {
        rolesStatus: (state) => state.roles,
      }),
      isAdminOrManager() {
        const isAdminOrRoot = this.rolesStatus.some((role) => ['admin', 'root'].includes(role.RoleName));
        return isAdminOrRoot;
      }

  
  },
  mounted() {
    this.getBusinessType();
    this.getLineData();
    
    this.patientChart += 1

    //this.getTopProducts();
    //this.getTopCustomers();
    /*    this.getCustomersDay(); */
  },

  methods: {
    forcesUpdate() {
      this.getLineData();
      console.log("se hizo force update");
      this.patientChart += 1
      this.chartLine += 1
      this.chartLineWeek += 1
      this.topProductChart += 1
      this.topCustomerChart += 1
    },
    /*getTopCustomers() {
      this.$API.dashboard.getTop().then((response) => {
        this.topCustomers = response;
        this.activeC = "dia";
      });
    },*/
    getBusinessType() {
      let user = JSON.parse(localStorage.getItem("user"))
      if (user && user.businesstype) {
        this.businessType = user.businesstype
      }
    },
    /*getTopCustomersw() {
      this.$API.dashboard.getTopCustomersw().then((response) => {
        this.topCustomers = response;
        this.activeC = "semana";
      });
    },*/
    /*getTopCustomersm() {
      this.$API.dashboard.getTopCustomersm().then((response) => {
        this.topCustomers = response;
        this.activeC = "mes";
      });
    },*/
    /*getTopCustomersy() {
      this.$API.dashboard.getTopCustomersy().then((response) => {
        this.topCustomers = response;
        this.activeC = "año(actual)";
      });
    },*/
    //pastel graphic
  /*   getCustomersYear() {
      this.$API.dashboard.CustomersYear().then((response) => {
        this.countCustomers = response;
        this.activePF = "años";
        this.dataP = {
          labels: ["Actual", "Anterior", "Dos anteriores"],
          datasets: [
            {
              backgroundColor: ["#41B883", "#E9351", "#00D6FF"],
              data: this.countCustomers,
            },
          ],
        };
      });
    }, */
    getCustomersMonth() {
      this.$API.dashboard.CustomersMonth().then((response) => {
        this.countCustomers = response;
        this.activePF = "meses";
        this.dataP = {
          labels: ["Actual", "Anterior", "Dos anteriores"],
          datasets: [
            {
              backgroundColor: ["#41B883", "#E9351", "#00D6FF"],
              data: this.countCustomers,
            },
          ],
        };
      });
    },
    /*getCustomersDay() {
      this.$API.dashboard.CustomersDay().then((response) => {
        this.countCustomers = response;
        this.dataP = {
          labels: ["Actual", "Anterior", "Dos anteriores"],
          datasets: [
            {
              backgroundColor: ["#41B883", "#E9351", "#00D6FF"],
              data: this.countCustomers,
            },
          ],
        };
        this.activePF = "dias";
      });
    },*/
    //end pastel graphic
    /*getTopProducts() {
      this.$API.dashboard.getProducts().then((response) => {
        this.topProducts = response;
        this.activeP = "dia";
      });
    },*/
    getLineData() {
      this.$API.dashboard.getLineData(this.businessType).then((response) => {
        this.lineData = response;
        this.activeP = "dia";
      });
    },
    /*getTopProductsw() {
      this.$API.dashboard.getProductsw().then((response) => {
        this.topProducts = response;
        this.activeP = "Semana";
      });
    },*/
    /*getTopProductsm() {
      this.$API.dashboard.getProductsm().then((response) => {
        this.topProducts = response;
        this.activeP = "mes";
      });
    },*/
    /*getTopProductsy() {
      this.$API.dashboard.getProductsy().then((response) => {
        this.topProducts = response;
        this.activeP = "año(actual)";
      });
    },*/
  },
};
</script>
<style scoped>
.number-statistics .c-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 40px;
  border-radius: 8px;
}

.dx-theme-generic-typography h3 {
  color: #000000;
}

.table-statistics .purple {
  background-color: purple;
}

.table-statistics .orange {
  background-color: orange;
}

.table-statistics h4 {
  color: #fff;
  padding: 10px 20px;
}
.box {
  border-style: solid;
  border-color: black;
  border-width: 1px;
  background-color: white;
  padding: 0.5rem;
  border-radius: 15px;
  margin: 0.5rem;
  max-width: 100%;
  box-sizing: border-box; 

  
}

@media (min-width: 992px) {
  .box-m {
    height: 99%;
  }
  .box-sm {
    height: 98%;
  }
}
@media (max-width: 600px) {
  .box {
    padding: 0.5rem; }
}

</style>
