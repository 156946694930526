<template>
    <div>

        <v-container>
            <v-row cols="12" md="12">
                <v-col cols="12" :md="12" :lg="selectedProd === null ? 12 : 9">
                    <v-autocomplete outlined dense 
                        v-model="selectedProd"
                        :items="productsToShow"
                        item-text="ID"
                        @change="searchProduct"
                        item-value="prod"
                        label="Nombre de producto"
                        attach
                    ></v-autocomplete>
                </v-col>
                <v-col cols="12" md="12" lg="3" v-if="selectedProd != null" class="d-flex justify-center">
                    <v-btn variant="tonal" @click="cleanSelection">
                     Limpiar Filtro
                    </v-btn>
                </v-col>
            </v-row>
            <v-row cols="12" md="12">
                <v-col cols="12" md="12">
                    <DxChart :data-source="productsWarehouses" palette="Violet" title="Productos por Almacenes">
                        <!-- <DxCommonSeriesSettings
                            type="spline"
                        /> -->
                        <DxSeries
                            argument-field="Warehouse"
                            value-field="Products"
                            :name="productLabel"
                            type="bar"
                            color="#75bef8"
                        />
                    </DxChart>
                </v-col>
            </v-row>
        </v-container>
        <alerts v-if="alert.show" v-on:close_alert="closeAlert" v-on:accept_alert="acceptAlert" :properties="alert">
        </alerts>
    </div>
</template>
<script>
    import {
        DxChart,
        DxSeries,
        // DxCommonSeriesSettings
    } from 'devextreme-vue/chart';
    import Alerts from '@/components/Alerts.vue'

    export default {
        name: "ChartProductsWarehouses",
        components: {
            DxChart,
            DxSeries,
            Alerts
            // DxCommonSeriesSettings
        },
        data(){
            return {
                alert: {
                    type: "success",
                    show: false,
                    header: "",
                    body: "",
                },
                selectedProd: null,
                warehouseSelected: null,
                warehouses: [],
                productsToShow: null,
                // productsWarehouses: [
                //     {
                //         Warehouse: 'Almacen 1',
                //         Products: 500
                //     },
                //     {
                //         Warehouse: 'Bodega 1',
                //         Products: 344
                //     },
                //     {
                //         Warehouse: 'Bodega 2',
                //         Products: 344
                //     }
                // ],
                productsWarehouses: null,
                productLabel: "Productos"
            }
        },
        mounted(){
            this.getProductsWarehouses()
            // console.log('aca vengo');

            this.getProducts();
            // console.log('aca voy');
            
        },
        methods: {
            getProductsWarehouses(){
                this.$API.dashboard.ProductsWarehouses()
                    .then(response => {
                        console.log(response);
                        this.productsWarehouses = response
                    })
                    .catch(error => {
                        console.log(error);
                        this.showAlert("warning", "Ocurrio el siguiente error al intentar cargar los Productos por Almacen", `${error}`);
                    })
            },
            getProducts(){
                try {
                    // console.log('entro');
                    this.$API.dashboard
                        .ProductsWarehousesChartOptions({branch: 1, business: 1})
                        .then(response => {
                            // console.log(response);
                            let values = [];
                            if(response.length > 0) {
                                response.forEach((element) => {
                                    values.push({ID: element.ItemComercialName, prod: {...element, type: "product"}})
                                });
                                
                            }
                            // console.log(values);
                            this.productsToShow = values;
                            // console.log(this.productsToShow);
                        })
                        .catch(error => {
                            console.log(error);
                            this.showAlert('warning', 'Ocurrio el siguiente error al intentar cargar las opciones "Productos por Almacenes"', error)
                        });
                    
                } catch (error) {
                    this.showAlert("danger", "Error", `No se pudo mostrar la informacion de los almacenes: ${error}`);
                }
            },
            searchProduct(){
                try {
                    this.$API.dashboard
                        .SpecificProductWarehouses(this.selectedProd.ID_Item)
                        .then( response => {
                            this.productsWarehouses = response
                        })
                        .catch( error => {
                            console.log(error);
                            this.showAlert("warning", `Ocurrio el siguiente error al intentar cargar los datos del producto "${this.selectedProd.ItemComercialName}"`, `${error}`);
                        });
                } catch (error) {
                    this.showAlert("danger", "Error", `No se pudo mostrar la informacion de los almacenes: ${error}`);
                }
            },
            cleanSelection(){
                try {
                    this.selectedProd = null,
                    this.getProductsWarehouses();
                } catch (error) {
                    this.showAlert("danger", "Error", `No se pudo mostrar la informacion de los almacenes: ${error}`);
                }
            },
            showAlert(type, header, body, options = null) {
                type = type == null ? "danger" : type;
                this.alert.type = type;
                this.alert.header = header;
                this.alert.body = body;
                this.alert.show = true;
                this.alert.options = options != null ? options : null;
            },
            closeAlert(){
                this.alert.show = false;
            }
        },
        computed: {
            allproductbyName() {
                let values = [];
                if(this.productsToShow.length > 0) {
                    this.productsToShow.forEach((element) => {
                        values.push({ID: element.ItemComercialName, prod: {...element, type: "product"}})
                    });
                
                }
                // console.log(values);
                return values;
        
            },
        }
    }
</script>
<style>
</style>