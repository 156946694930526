<template>
  <div
    :style="{
     
      flexWrap: 'wrap',
      minHeight: '600px',
      borderRadius: '5%',
    }"
    class="white pa-3 radius5"
  >
    <div :style="{ flex: '45%', margin: '0%' }">
      <v-card flat>
        <v-card-title>Top Productos vendidos </v-card-title>
        <v-card-text>
          <div :style="{ marginBlock: '5px' }">
            <div
              :style="{
                display: 'flex',
                flexWrap: 'wrap',
                flex: 1,
                textAlign: 'left',
              }"
            >
              <div :style="{ flex: 1, fontWeight: 'bolder' }">
                Filtrar por fecha
              </div>
            </div>
            <div>
              <div
                :style="{
                  display: 'flex',
                  flexWrap: 'wrap',
                  flexDirection: 'column',
                  alignItems: 'left',
                }"
              >
                <div class="dx-field">
                  <div class="dx-field-label">Desde</div>
                  <div class="dx-field-value">
                    <date-picker
                      v-model="start"
                      @dateSelected="
                        (startP) => {
                          start = startP;
                        }
                      "
                      :value="start"
                      type="date"
                      :styling-mode="'underlined'"
                      :style="{ width: '150px' }"
                    ></date-picker>
                  </div>
                </div>

                <div>
                  <div class="dx-field" style="margin-bottom: 20px">
                    <div
                      class="dx-field-label"
                      style="display: inline-block; vertical-align: middle"
                    >
                      Hasta
                    </div>
                    <div class="dx-field-value" style="display: inline-block">
                      <date-picker
                        v-model="end"
                        @dateSelected="
                          (endP) => {
                            end = endP;
                          }
                        "
                        :value="end"
                        type="date"
                        :styling-mode="'underlined'"
                        :style="{ width: '150px' }"
                      ></date-picker>
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <DxButton
                  :width="100"
                  text="aplicar"
                  type="default"
                  styling-mode="contained"
                  @click="getTopProductsRange()"
                />
              </div>
            </div>
            <div
              :style="{
                display: 'flex',
                flexWrap: 'wrap',
                gap: '3%',
                marginTop: '5px',
              }"
            >
              <div :style="{ flex: '35%' }">
                <DxCheckBox
                  :value="showProducts"
                  @value-changed="manageProducts"
                  text="Ver Productos"
                />
              </div>
              <div :style="{ flex: '35%' }">
                <DxCheckBox
                  :value="showServices"
                  @value-changed="manageServices"
                  text="Ver Servicios"
                />
              </div>

              <!--    <div :style="{ flex: 1 }">Viendo ùltimo : {{ activeP }}</div> -->
            </div>
          </div>
          <GeneralFilter
            :search="{}"
            :filterEndpoint="filterPromise"
            @filtered="filterHandler"
            @emptyFilter="clearFilter"
            :is-auto-scrolled="true"
            @exporting="onExporting"
          >
            <DxDataGrid
              :data-source="!isFiltered ? topProducts : topProductsFiltered"
              key-expr="ID_Item"
              :show-row-lines="showRowLines"
              :show-borders="showBorders"
              :row-alternation-enabled="rowAlternationEnabled"
              :scrolling="{ useNative: true }"
              :column-auto-width="true"
            >
              <DxExport :enabled="true" />

              <DxPaging :page-size="5" />
              <!--  <DxPaging :page-size="10"/> -->
              <DxPager
                :visible="true"
                :show-page-size-selector="false"
                :show-info="true"
                :show-navigation-buttons="true"
              />
              <DxColumn data-field="ID_Item" caption="ID"></DxColumn>

              <DxColumn
                data-field="ItemName"
                caption="Producto/Servicio"
              ></DxColumn>
              <DxColumn
                data-field="sold"
                caption="Cantidad vendida"
              ></DxColumn>
              <DxColumn data-field="amountFormatted" caption="Monto"></DxColumn>
            </DxDataGrid>
          </GeneralFilter>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
//import Block from "@/components/Block"
// eslint-disable-next-line no-unused-vars
/* import ChartBar from "../DashboardStats/ChartBar.vue";*/
/* import ChartLine from "../DashboardStats/ChartLine.vue"; */
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
/*
import "@vuepic/vue-datepicker/dist/main.css"; */

import {
  DxDataGrid,
  DxColumn,
  DxPaging,
  DxPager,
  DxExport,
} from "devextreme-vue/data-grid";
import GeneralFilter from "../GeneralFilter.vue";
import { printToExcel } from "@/helpers/printToexcel";
import DxButton from "devextreme-vue/button";
import DatePicker from "../../components/DatePicker";
import moment from "moment";
import { DxCheckBox } from "devextreme-vue/check-box";
import { multipleCriteriaFilter } from "../../helpers/object";

ChartJS.register(ArcElement, Tooltip, Legend);
export default {
  name: "TopProductChart",
  components: {
    /*  ChartBar, */
    /*  ChartLine, */
    DxDataGrid,
    DxColumn,
    GeneralFilter,

    DxPaging,
    DxButton,
    DxCheckBox,
    DxPager,
    DatePicker,
    DxExport,
  },
  data() {
    return {
      start: new Date(),
      end: new Date(),
      products: [],
      services: [],
      panel: 0,
      showCustomer: false,
      topCustomers: [],
      topProducts: [],
      allData: [],
      activeP: "dia",
      activeC: "dia",
      activePF: "Dias",
      countCustomers: [],
      showRowLines: true,
      showBorders: true,
      showServices: true,
      showProducts: true,
      rowAlternationEnabled: true,
      search: {},
      showPageSizeSelector: true,
      showInfo: true,
      showNavButtons: true,
      dataP: {
        labels: ["Actual", "Anterior", "Dos anteriores"],
        datasets: [
          {
            backgroundColor: ["#41B883", "#E9351", "#00D6FF"],
            data: [10, 10, 10],
          },
        ],
      },
      options: {
        responsive: false,
        width: "200px",
        heigth: "200px",
        maintainAspectRatio: false,
      },
      topProductsFiltered: [],
      isFiltered: false,
      Currency: null,
    };
  },

  mounted() {
    this.Currency = JSON.parse(localStorage.getItem("branch")).Currency;
    this.getTopCustomers();
    this.getTopProducts();
    /* this.getCustomersDay(); */
  },

  methods: {
    onExporting(e) {
      printToExcel(e, "Top Productos vendidos");
    },
    setCurrency() {
      this.topProducts.map((customer) => {
        customer.amountFormatted = `${this.Currency}${customer.amount}`;
      });
    },
    filterHandler(response) {
      this.isFiltered = true;
      this.topProductsFiltered = response;
    },
    clearFilter() {
      this.isFiltered = false;
    },
    filterPromise(filterObj) {
      const filterArray = ["ID_Item", "ItemName", "sold", "amount"];
      return new Promise((resolve, reject) => {
        try {
          resolve(
            multipleCriteriaFilter(filterArray, filterObj, this.topProducts)
          );
        } catch (error) {
          reject(error);
        }
      });
    },
    manageServices() {
      if (this.showServices == false) {
        this.showServices = true;
        this.topProducts = this.topProducts.concat(this.services);
      } else {
        this.showServices = false;
        // this.topProducts = this.topProducts.filter(item => item.ItemType != "SERVICE")
        this.topProducts = this.showProducts ? this.products : [];
      }
    },
    manageProducts() {
      if (this.showProducts == false) {
        this.showProducts = true;

        this.topProducts = this.topProducts.concat(this.products);
        this.setCurrency();
      } else {
        this.showProducts = false;
        // this.topProducts = this.topProducts.filter(item => item.ItemType != "PRODUCT")
        this.topProducts = this.showServices ? this.services : [];
      }
    },
    getTopCustomers() {
      this.$API.dashboard.getTop().then((response) => {
        this.topCustomers = response;
        this.activeC = "dia";
        this.setCurrency();
      });
    },
    getTopCustomersRange() {
      let fstart = moment(this.start).format("yyyy-dd-mm");
      let fend = moment(this.end).format("yyyy-dd-mm");
      this.$API.dashboard
        .getTopCustomersRange(fstart, fend)
        .then((response) => {
          this.topCustomers = response;
          this.activeC = "dia";
          this.setCurrency();
        });
    },
    getTopCustomersw() {
      this.$API.dashboard.getTopCustomersw().then((response) => {
        this.topCustomers = response;
        this.activeC = "semana";
        this.setCurrency();
      });
    },
    getTopCustomersm() {
      this.$API.dashboard.getTopCustomersm().then((response) => {
        this.topCustomers = response;
        this.activeC = "mes";
        this.setCurrency();
      });
    },
    getTopCustomersy() {
      this.$API.dashboard.getTopCustomersy().then((response) => {
        this.topCustomers = response;
        this.activeC = "año(actual)";
        this.setCurrency();
      });
    },
    //pastel graphic
    getCustomersYear() {
      this.$API.dashboard.CustomersYear().then((response) => {
        this.countCustomers = response;
        this.activePF = "años";
        this.dataP = {
          labels: ["Actual", "Anterior", "Dos anteriores"],
          datasets: [
            {
              backgroundColor: ["#41B883", "#E9351", "#00D6FF"],
              data: this.countCustomers,
            },
          ],
        };
      });
    },
    getCustomersMonth() {
      this.$API.dashboard.CustomersMonth().then((response) => {
        this.countCustomers = response;
        this.activePF = "meses";
        this.dataP = {
          labels: ["Actual", "Anterior", "Dos anteriores"],
          datasets: [
            {
              backgroundColor: ["#41B883", "#E9351", "#00D6FF"],
              data: this.countCustomers,
            },
          ],
        };
      });
    },
    getCustomersDay() {
      this.$API.dashboard.CustomersDay().then((response) => {
        this.countCustomers = response;
        this.dataP = {
          labels: ["Actual", "Anterior", "Dos anteriores"],
          datasets: [
            {
              backgroundColor: ["#41B883", "#E9351", "#00D6FF"],
              data: this.countCustomers,
            },
          ],
        };
        this.activePF = "dias";
      });
    },
    //end pastel graphic
    getTopProducts() {
      this.$API.dashboard.getProducts().then((response) => {
        this.topProducts = response;
        this.allData = response;
        this.products = response.filter((item) => item.ItemType == "PRODUCT");
        this.services = response.filter((item) => item.ItemType == "SERVICE");
        
        this.activeP = "dia";
        this.setCurrency();
      });
    },
    getTopProductsRange() {
  this.$API.dashboard
    .getProductsRange(
      new Date(this.formatDate(this.start)),
      new Date(this.formatDate(this.end))
    )
    .then((response) => {
      this.allData = response;
      // Verifica los datos recibidos

      // Filtrar productos y servicios
      this.products = response.filter((item) => item.ItemType === 'PRODUCT');
      this.services = response.filter((item) => item.ItemType === 'SERVICE');

      
     

      // Actualizar topProducts
      this.topProducts = [...this.products, ...this.services];
      this.activeP = "dia";
      this.setCurrency();
    });
}
,
    formatDate(date) {
      const parts = date.split("/");
      const aux = parts[0];
      parts[0] = parts[1];
      parts[1] = aux;
      return parts.join("/");
    },
    getTopProductsw() {
      this.$API.dashboard.getProductsw().then((response) => {
        this.topProducts = response;
        this.activeP = "Semana";
      });
    },
    getTopProductsm() {
      this.$API.dashboard.getProductsm().then((response) => {
        this.topProducts = response;
        this.activeP = "mes";
      });
    },
    getTopProductsy() {
      this.$API.dashboard.getProductsy().then((response) => {
        this.topProducts = response;
        this.activeP = "año(actual)";
      });
    },
  },
};
</script>
<style scoped>
.notScrolled {
  min-width: none !important;
}
.number-statistics .c-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 40px;
  border-radius: 8px;
}

.dx-theme-generic-typography h3 {
  color: #000000;
}

.table-statistics .purple {
  background-color: purple;
}

.table-statistics .orange {
  background-color: orange;
}

.table-statistics h4 {
  color: #fff;
  padding: 10px 20px;
}
.dx-field {
  display: flex;
  align-items: center;
}
.dx-field-label {
  margin-right: 10px;
}
</style>
