<template>
  <div>
    <v-tabs v-model="tab" class="d-flex align-center justify-center">
      <v-tab href="#tab-1">Años</v-tab>
      <v-tab href="#tab-2">Meses</v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item value="tab-1">
        <div
          id="chart-demo"
          class="white pa-3 p-5"
          :style="{
            borderRadius: '5%',
          }"
        >
          <div>
            <!-- year -->
            <v-row class="mt-2 d-flex justify-start">
              <v-col  cols="12" class="dx-field ml-2 dx-field ml-3 " style="display: flex;flex-wrap: wrap;">
                <div class="dx-field-label" style="min-width: 270px; margin-right: 5px">
                  Selecciona Primer Año
                </div>
                <v-select
                  class="select-size"
                  label="Año"
                  v-model="start"
                  :items="years"
                  outlined
                  attach
                  dense
                  width="100px"
                ></v-select>
              </v-col>
              <v-col cols="12" class="dx-field ml-2 dx-field ml-3">
                <div class="dx-field-label" style="min-width: 270px; margin-right: 5px">
                  Selecciona Segundo Año
                </div>
                <v-select
                  class="select-size"
                  label="Año"
                  v-model="end"
                  :items="years"
                  outlined
                  attach
                  dense
                ></v-select>
              </v-col>
            </v-row>
            <!-- year -->

            <div>
              <DxButton
                :width="100"
                text="aplicar"
                type="default"
                styling-mode="contained"
                @click="getTopProductsRange()"
              />
            </div>
          </div>
          <DxChart
            id="chart"
            :data-source="sharingStatisticsInfo"
            palette="Violet"
            title="Comparación periodo actual y anterior"
          >
            <DxCommonSeriesSettings :type="type" argument-field="month" />
            <DxCommonAxisSettings>
              <DxGrid :visible="true" />
            </DxCommonAxisSettings>
            <DxSeries
              v-for="architecture in architectureSources"
              :key="architecture.name"
              :value-field="architecture.value"
              :name="architecture.name"
            />
            <DxMargin :bottom="20" />
            <DxArgumentAxis :allow-decimals="true" :axis-division-factor="60">
              <DxLabel>
                <DxFormat type="decimal" />
              </DxLabel>
            </DxArgumentAxis>
            <DxLegend vertical-alignment="top" horizontal-alignment="right" />
            <DxExport :enabled="true" />
            <DxTooltip :enabled="true" />
          </DxChart>
          <!--    <div class="options">
            <div class="caption">Options</div>
            <div class="option">
              <span>Series Type</span>
              <DxSelectBox :data-source="types" v-model="this.type" />
            </div>
          </div> -->
        </div>
      </v-tab-item>
      <v-tab-item value="tab-2">
        <div
          id="chart-demo"
          class="white pa-3 p-5"
          :style="{
            borderRadius: '5%',
          }"
        >
          <div class="mt-5">
            <!-- first year/month -->
            <v-row class="d-flex justify-start ml-3">
              <div class="dx-field">
                <div class="dx-field-label" style="min-width: 175px; margin-right: 5px">
                  Selecciona Primer Año
                </div>
                <v-select
                  class="select-size"
                  @change="getMonthsStart"
                  label="Año"
                  v-model="selectedYearStart"
                  :items="years"
                  outlined
                  attach
                  dense
                ></v-select>
              </div>
              <div class="dx-field field-size">
                <div class="dx-field-label" style="min-width: 175px; margin-right: 5px">
                  Selecciona Primer Mes
                </div>
                <v-select
                  class="select-size"
                  :loading="loadingStart"
                  :disabled="checkDateSelectedStart"
                  label="Mes"
                  v-model="startMonth"
                  :items="monthsStart"
                  item-text="monthName"
                  item-value="monthIndex"
                  outlined
                  attach
                  dense
                >
                  <template v-slot:item="{ item }">
                    <v-list-item-content>
                      <v-list-item-title
                        :class="{
                          'font-weight-bold': dbMonthsStart.some(
                            (month) => month.monthIndex === item.monthIndex
                          ),
                        }"
                      >
                        {{ item.monthName }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-select>
              </div>
            </v-row>
            <!-- first year/month -->
            <!-- second year/month -->
            <v-row class="d-flex justify-start ml-3">
              <div class="dx-field">
                <div class="dx-field-label" style="min-width: 175px; margin-right: 5px">
                  Selecciona Segundo Año
                </div>
                <v-select
                  class="select-size"
                  @change="getMonthsEnd"
                  label="Año"
                  v-model="selectedYearEnd"
                  :items="years"
                  outlined
                  attach
                  dense
                ></v-select>
              </div>
              <div class="dx-field field-size">
                <div class="dx-field-label" style="min-width: 175px; margin-right: 5px">
                  Selecciona Segundo Mes
                </div>
                <v-select
                  class="select-size"
                  :loading="loadingEnd"
                  :disabled="checkDateSelectedEnd"
                  label="Mes"
                  v-model="endMonth"
                  :items="monthsEnd"
                  item-text="monthName"
                  item-value="monthIndex"
                  outlined
                  attach
                  dense
                >
                  <template v-slot:item="{ item }">
                    <v-list-item-content>
                      <v-list-item-title
                        :class="{
                          'font-weight-bold': dbMonthsEnd.some(
                            (month) => month.monthIndex === item.monthIndex
                          ),
                        }"
                      >
                        {{ item.monthName }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-select>
              </div>
            </v-row>
            <!-- second year/month -->

            <div>
              <DxButton
                :width="100"
                text="aplicar"
                type="default"
                styling-mode="contained"
                @click="getTopProductsRangeWithMonths()"
              />
            </div>
          </div>
          <DxChart
            id="chart"
            :data-source="sharingStatisticsInfo"
            palette="Violet"
            title="Comparación periodo actual y anterior"
          >
            <DxCommonSeriesSettings :type="type" argument-field="day" />
            <DxCommonAxisSettings>
              <DxGrid :visible="true" />
            </DxCommonAxisSettings>
            <DxSeries
              v-for="architecture in architectureSources"
              :key="architecture.name"
              :value-field="architecture.value"
              :name="architecture.name"
            />
            <DxMargin :bottom="20" />
            <DxArgumentAxis :allow-decimals="true" >
              <DxLabel>
                <DxFormat type="decimal" />
              </DxLabel>
            </DxArgumentAxis>
            <DxLegend vertical-alignment="top" horizontal-alignment="right" />
            <DxExport :enabled="true" />
            <DxTooltip :enabled="true" />
          </DxChart>
          <!--    <div class="options">
            <div class="caption">Options</div>
            <div class="option">
              <span>Series Type</span>
              <DxSelectBox :data-source="types" v-model="this.type" />
            </div>
          </div> -->
        </div>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>
<script>
import {
  DxChart,
  DxSeries,
  DxArgumentAxis,
  DxCommonSeriesSettings,
  DxCommonAxisSettings,
  DxGrid,
  DxExport,
  DxLegend,
  DxMargin,
  DxTooltip,
  DxLabel,
  DxFormat,
} from "devextreme-vue/chart";
/* import DxSelectBox from 'devextreme-vue/select-box'; */

/* import { architectureSources, sharingStatisticsInfo } from './data.js'; */
import moment from "moment";
import DxButton from "devextreme-vue/button";
export default {
  components: {
    /*  DxSelectBox, */
    DxChart,
    DxSeries,
    DxArgumentAxis,
    DxCommonSeriesSettings,
    DxCommonAxisSettings,
    DxGrid,
    DxExport,
    DxLegend,
    DxMargin,
    DxTooltip,
    DxLabel,
    DxFormat,
    DxButton,
  },

  data() {
    return {
      start: 0,
      end: 0,
      years: [],
      monthsStart: [],
      monthsEnd: [],
      dbMonthsStart: [],
      dbMonthsEnd: [],
      tab: null,
      selectedYearStart: null,
      selectedYearEnd: null,
      loadingStart: null,
      loadingEnd: null,
      sharingStatisticsInfo: [
        /* {
        year: 1997,
        smp: 263,
        mmp: 208,
        cnstl: 9,
        cluster: 1,
      }, {
        year: 1999,
        smp: 169,
        mmp: 270,
        cnstl: 61,
        cluster: 7,
      }, {
        year: 2001,
        smp: 57,
        mmp: 261,
        cnstl: 157,
        cluster: 45,
      }, {
        year: 2003,
        smp: 0,
        mmp: 154,
        cnstl: 121,
        cluster: 211,
      }, {
        year: 2005,
        smp: 0,
        mmp: 97,
        cnstl: 39,
        cluster: 382,
      }, {
        year: 2007,
        smp: 0,
        mmp: 83,
        cnstl: 3,
        cluster: 437,
      } */
      ],
      architectureSources: [
        /*  { value: 'smp', name: 'SMP' },
         { value: 'mmp', name: 'MMP' },
         { value: 'cnstl', name: 'Cnstl' },
         { value: 'cluster', name: 'Cluster' }, */
      ],
      types: ["spline", "stackedspline", "fullstackedspline"],
      type: "spline",
      months: [
        { monthIndex: 1, monthName: "Enero" },
        { monthIndex: 2, monthName: "Febrero" },
        { monthIndex: 3, monthName: "Marzo" },
        { monthIndex: 4, monthName: "Abril" },
        { monthIndex: 5, monthName: "Mayo" },
        { monthIndex: 6, monthName: "Junio" },
        { monthIndex: 7, monthName: "Julio" },
        { monthIndex: 8, monthName: "Agosto" },
        { monthIndex: 9, monthName: "Septiembre" },
        { monthIndex: 10, monthName: "Octubre" },
        { monthIndex: 11, monthName: "Noviembre" },
        { monthIndex: 12, monthName: "Diciembre" },
      ],
    };
  },
  computed: {
    checkDateSelectedStart() {
      return this.selectedYearStart === null ? true : false;
    },
    checkDateSelectedEnd() {
      return this.selectedYearEnd === null ? true : false;
    },
  },
  mounted() {
    let currentYear = moment().format("YYYY");
    let previousYear = moment().subtract(1, "year").format("YYYY");
    this.start = previousYear;
    this.end = currentYear;
    this.architectureSources = [
      { value: currentYear, name: currentYear },
      { value: previousYear, name: previousYear },
    ];
    this.getYears();

    // this.getLineData();
    this.getTopProductsRange();
  },
  methods: {
    getYears() {
      this.$API.dashboard.getYears().then((response) => {
        this.years = response;
      });
    },
    getMonthsStart() {
      this.loadingStart = true;
      this.$API.dashboard
        .getMonths({ year: this.selectedYearStart })
        .then((response) => {
          this.monthsStart = this.months;
          this.dbMonthsStart = response;
        })
        .finally(() => {
          this.loadingStart = false;
        });
    },
    getMonthsEnd() {
      this.loadingEnd = true;
      this.$API.dashboard
        .getMonths({ year: this.selectedYearEnd })
        .then((response) => {
          this.monthsEnd = this.months;
          this.dbMonthsEnd = response;
        })
        .finally(() => {
          this.loadingEnd = false;
        });
    },
    getLineData() {
      this.$API.dashboard.LineGraph().then((response) => {
        this.sharingStatisticsInfo = response;
      });
    },
    getTopProductsRange() {
      this.$API.dashboard.getYearsCustom(this.start, this.end).then((response) => {
        console.log(response);
        this.sharingStatisticsInfo = response;
        this.architectureSources = [
          {
            value: Object.keys(response[0])[0].toString(),
            name: Object.keys(response[0])[0],
          },
          {
            value: Object.keys(response[0])[1].toString(),
            name: Object.keys(response[0])[1],
          },
        ];

        console.log(this.architectureSources);
        /*    this.architectureSources[0]['name'] = Object.keys(response[0])[0]
             this.architectureSources[0]['value'] = (response[0][0])
             this.architectureSources[1]['name'] = Object.keys(response[0])[1]
             this.architectureSources[1]['value'] = (response[0][1]) */
      });
    },
    getTopProductsRangeWithMonths() {
      const datesRange = {
        startYear: this.selectedYearStart,
        endYear: this.selectedYearEnd,
        startMonth: this.startMonth,
        endMonth: this.endMonth,
      };
      this.$API.dashboard.getYearsMonthsCustom(datesRange).then((response) => {
        console.log(response);
        const { first, second } = this.showDateNames();
        console.log(first, second);
        this.sharingStatisticsInfo = response;
        this.architectureSources = [
          {
            value: Object.keys(response[0])[1].toString(),
            name: first,
          },
          {
            value: Object.keys(response[0])[2].toString(),
            name: second,
          },
        ];

        console.log(this.architectureSources);
        /*    this.architectureSources[0]['name'] = Object.keys(response[0])[0]
             this.architectureSources[0]['value'] = (response[0][0])
             this.architectureSources[1]['name'] = Object.keys(response[0])[1]
             this.architectureSources[1]['value'] = (response[0][1]) */
      });
    },
    showDateNames() {
      const getMonthName = (months, targetMonth) => {
        const foundMonth = Array.isArray(months)
          ? months.find((month) => month.monthIndex === targetMonth)
          : months && months.monthIndex === targetMonth
          ? months.monthName
          : null;

        return foundMonth ? foundMonth.monthName : null;
      };

      const first =
        getMonthName(this.monthsStart, this.startMonth) +
        " del " +
        this.selectedYearStart;
      const second =
        getMonthName(this.monthsEnd, this.endMonth) + " del " + this.selectedYearEnd;
      console.log(first, second);
      return { first, second };
    },
  },
};
</script>
<style>
.options {
  padding: 20px;
  background-color: rgba(191, 191, 191, 0.15);
  margin-top: 20px;
}

.option {
  margin-top: 10px;
}

.caption {
  font-size: 18px;
  font-weight: 500;
}

.option > span {
  margin-right: 14px;
}

.option > .dx-widget {
  display: inline-block;
  vertical-align: middle;
}

.select-size {
  max-width: 150px !important;
  min-width: 100px !important;
  margin-right: 5px !important;
}

.field-size {
  margin-left: 30px !important;
}

@media screen and (max-width: 1495px) {
  .field-size {
    margin-left: 0 !important;
  }

  .select-size {
    max-width: 60%;
  }
}
</style>
