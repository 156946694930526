<template>
  <div
    id="chart-demo"
    class="white pa-3 p-5"
    :style="{
      borderRadius: '5%',
    }"
  >
    <div>
      <div style="display: flex; flex-wrap: wrap">
        <div class="dx-field m-2" style="display: flex; align-items: center; flex-wrap: wrap;">
          <div
            class="dx-field-label"
            style="min-width: 270px; margin-right: 5px"
          >
            Selecciona dia de Primer Semana
          </div>
          <div class="dx-field-value">
            <date-picker
              @dateSelected="
                (startP) => {
                  start = startP;
                }
              "
              v-model="start"
              :value="start"
            ></date-picker>
          </div>
        </div>
        <div class="dx-field m-2" style="display: flex; align-items: center; flex-wrap: wrap;">
          <div
            class="dx-field-label"
            style="min-width: 270px; margin-right: 5px"
          >
            Selecciona dia de Segunda Semana
          </div>
          <div class="dx-field-value">
            <date-picker
              @dateSelected="(endP) => (end = endP)"
              v-model="end"
              :value="end"
            ></date-picker>
          </div>
        </div>
      </div>
      <div>
        <DxButton
          :width="100"
          text="aplicar"
          type="default"
          styling-mode="contained"
          @click="getTopProductsRange()"
        />
      </div>
    </div>
    <DxChart
      id="chart"
      :data-source="sharingStatisticsInfo"
      palette="Ocean"
      title="Comparación semana actual y anterior"
    >
      <DxCommonSeriesSettings :type="type" argument-field="day" />
      <DxCommonAxisSettings>
        <DxGrid :visible="true" />
      </DxCommonAxisSettings>
      <DxSeries
        v-for="architecture in architectureSources"
        :key="architecture.value"
        :value-field="architecture.value"
        :name="architecture.name"
      />
      <DxMargin :bottom="20" />
      <DxArgumentAxis :allow-decimals="true" :axis-division-factor="60">
        <DxLabel>
          <DxFormat type="decimal" />
        </DxLabel>
      </DxArgumentAxis>
      <DxLegend vertical-alignment="top" horizontal-alignment="right" />
      <DxExport :enabled="true" />
      <DxTooltip :enabled="true" />
    </DxChart>
    <!--    <div class="options">
      <div class="caption">Options</div>
      <div class="option">
        <span>Series Type</span>
        <DxSelectBox :data-source="types" v-model="this.type" />
      </div>
    </div> -->
  </div>
</template>
<script>
import {
  DxChart,
  DxSeries,
  DxArgumentAxis,
  DxCommonSeriesSettings,
  DxCommonAxisSettings,
  DxGrid,
  DxExport,
  DxLegend,
  DxMargin,
  DxTooltip,
  DxLabel,
  DxFormat,
} from "devextreme-vue/chart";
import DxButton from "devextreme-vue/button";
import DatePicker from "../../components/DatePicker";
import moment from "moment";

/* import DxSelectBox from 'devextreme-vue/select-box'; */

/* import { architectureSources, sharingStatisticsInfo } from './data.js'; */
/* import moment from 'moment'; */
export default {
  components: {
    /*  DxSelectBox, */
    DxChart,
    DxSeries,
    DxArgumentAxis,
    DxCommonSeriesSettings,
    DxCommonAxisSettings,
    DxGrid,
    DxExport,
    DxLegend,
    DxMargin,
    DxTooltip,
    DxButton,
    DxLabel,
    DxFormat,
    DatePicker,
  },

  data() {
    return {
      start: new Date(),
      end: new Date(),
      sharingStatisticsInfo: [],
      architectureSources: [],
      types: ["spline", "stackedspline", "fullstackedspline"],
      type: "spline",
    };
  },
  mounted() {
    let currentWeek = moment().format("DD/MM/YYYY");
    let previousWeek = moment().subtract(1, "weeks").format("DD/MM/YYYY");

    this.start = previousWeek;
    this.end = currentWeek;
    this.architectureSources = [
      { value: "actual", name: "primer Semana" },
      { value: "anterior", name: "segunda semana" },
    ];

    this.getLineData();
    this.getTopProductsRange();
  },
  methods: {
    transformDay(data) {
      if (data) {
        data.forEach((element) => {
          switch (element.day) {
            case "Sunday":
              element.day = "Domingo";
              break;
            case "Monday":
              element.day = "Lunes";
              break;
            case "Tuesday":
              element.day = "Martes";
              break;
            case "Wednesday":
              element.day = "Miércoles";
              break;
            case "Thursday":
              element.day = "Jueves";
              break;
            case "Friday":
              element.day = "Viernes";
              break;
            case "Saturday":
              element.day = "Sábado";
              break;
            default:
              break;
          }
        });
        return data;
      } else {
        return null;
      }
    },
    getLineData() {
      this.$API.dashboard.LineWeek().then((response) => {
        this.sharingStatisticsInfo = this.transformDay(response);
      });
    },
    getTopProductsRange() {
     
      this.$API.dashboard
        .getWeeksCustom(
          new Date(this.formatDate(this.start)),
          new Date(this.formatDate(this.end))
        )
        .then((response) => {
          this.sharingStatisticsInfo = this.transformDay(response);
        });
    },
    formatDate(date) {
      const parts = date.split("/");
      const aux = parts[0];
      parts[0] = parts[1];
      parts[1] = aux;
      return parts.join("/");
    },
  },
};
</script>
<style>
.options {
  padding: 20px;
  background-color: rgba(191, 191, 191, 0.15);
  margin-top: 20px;
}

.option {
  margin-top: 10px;
}

.caption {
  font-size: 18px;
  font-weight: 500;
}

.option > span {
  margin-right: 14px;
}

.option > .dx-widget {
  display: inline-block;
  vertical-align: middle;
}
</style>
